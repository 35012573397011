import React, { useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cn from 'classnames';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg';
import { Accordion } from '../../../../components/Accordion';
import { AccordionHeader } from '../../../../components/Accordion/components/Header';
import Title from '../../../../components/Title/Title';
import { translate } from '../../../../utils/translate';
import { useSnippetsAtPage } from '../../../../hooks/useSnippetsAtPage';
import { useSnippets } from '../../../../hooks/useSnippets';
import SnippetItem from './components/SnippetItem';
import SnippetAtPage from './components/SnippetAtPage';
import SnippetAtPageDropWrap from './components/SnippetAtPageDropWrap';
import SnippetDnD from '../../../PageEditor/components/SnippetDnD';
import styles from './styles.module.scss';

const SnippetsList = ({ pageId, onConfigure, hidden }) => {
  const { snippets } = useSnippets();

  const {
    snippetsAtPage,
    snippetsAtPageHighlight,
    onRemoveSnippet,
    onSelectSnippet,
  } = useSnippetsAtPage(pageId);

  const items = useMemo(() => snippetsAtPage
    .filter((ps) => ps.page === pageId), [snippetsAtPage, pageId]);

  const makeStructure = (array, parent = null) => array
    .filter((item) => item.parent === parent)
    .sort((a, b) => a.order - b.order)
    .map((item, index) => {
      const snippet = snippets.find(({ id }) => id === item.snippet);
      const hl = snippetsAtPageHighlight && item ? snippetsAtPageHighlight[item.id] : undefined;
      // console.log(`id: ${item.id}`, `order: ${item.order} === index: ${index + 1}`);
      const snippetItem = (
        <SnippetAtPage
          index={index}
          snippet={snippet}
          snippetAtPage={item}
        >

          <SnippetItem
            item={item}
            snippet={snippet}
            hightlight={hl}
            onConfigure={onConfigure}
            onRemove={onRemoveSnippet}
            onClick={onSelectSnippet}
          />

        </SnippetAtPage>
      );

      if (snippet?.is_container) {
        const content = makeStructure(array, item.id);

        const has_child = content.length > 0;

        if (!has_child) {
          content.push({id: -item.id, content: <div index={0} style={{color: '#5E5E5D'}}>(empty container)<br/>...drop something here</div>});
        }

        return {
          id: item.id,
          header: (
            <AccordionHeader
              hasChildren={true}
              title={snippetItem}
              leftIcons={<ChevronRight width={24} height={24} />}
            />
          ),
          content,
        };
      }

      return {
        id: item.id,
        content: snippetItem,
      };
    });

  const snippetAtPageList = (snippets.length > 0 && items.length > 0)
    ? makeStructure(items) : [];

  return (
    <div className={cn([styles.container, hidden && styles.hidden])}>

      <Title className={styles.title}>{translate('Snippets list on the page')}</Title>

      <SnippetDnD
        className={styles.list}
        pageId={pageId}
      >

        <PerfectScrollbar>

          {snippetAtPageList.length > 0 && snippetAtPageList.map((item, index) => (
            <Accordion
              withBorder={false}
              item={item}
              key={index}
              wrapper={SnippetAtPageDropWrap}
            />
          ))}

        </PerfectScrollbar>

      </SnippetDnD>

    </div>
  );
};

export default SnippetsList;
