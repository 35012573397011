import { requestWrapper } from '../../utils/requestWrapper';
import { SNIPPETS_AT_PAGE } from '../../constants/type';
import { API_SNIPPETS_AT_PAGE_URL } from '../../constants/api';
import { wrapSimple, wrapSnippetPage } from '../../utils/actionWraps';
import { baseApi } from '../../utils/apiService';
import appStore from '../../utils/store';
import { createAction } from 'redux-actions';

const getCurrentUi = () => appStore.getState().ui;

export const getList = async (pageId) => {
  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.GET_LIST,
    `${API_SNIPPETS_AT_PAGE_URL}?${pageId ? 'page=' : ''}${pageId ? pageId : ''}`,
    'get',
    false,
    {pageId},
    true
  );

  wrapSimple(request);
};

export const createSnippet = async (requestData) => {
  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.SET_SNIPPET,
    `${API_SNIPPETS_AT_PAGE_URL}?key=${baseApi.AddToken}`,
    'post',
    false,
    {
      ...requestData,
      ui: getCurrentUi(),
    },
    true,
  );

  wrapSimple(request);
};

export const setHighlight = async (payload) => {
  appStore.dispatch(createAction(SNIPPETS_AT_PAGE.SET_HIGHTLIGHT)(payload));
};

export const removeSnippet = async (id) => {
  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.REMOVE_SNIPPET,
    `${API_SNIPPETS_AT_PAGE_URL}${id}/?key=${baseApi.AddToken}`,
    'delete',
    false,
    {
      ui: getCurrentUi(),
    },
    true,
  );

  wrapSnippetPage(request, { onSuccess: getList });
};

export const selectSnippet = async (id) => {
  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.SELECT_SNIPPET,
    `${API_SNIPPETS_AT_PAGE_URL}${id}/select/?key=${baseApi.AddToken}`,
    'get',
    false,
    {
      ui: getCurrentUi(),
    },
    true,
  );

  wrapSnippetPage(request);
};

export const editSnippet = async (requestData) => {
  const id = requestData.get ? requestData.get('id') : requestData.id;

  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.CHANGE_ORDER,
    `${API_SNIPPETS_AT_PAGE_URL}${id}/?key=${baseApi.AddToken}`,
    'put',
    false,
    {
      ...requestData,
      ui: getCurrentUi(),
    },
    true,
  );

  wrapSnippetPage(request, { onSuccess: getList });
};

export const getSnippetProps = (id) => {
  const url = `${API_SNIPPETS_AT_PAGE_URL + id}/values/`;

  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.GET_PROPS,
    url,
    'get',
    false,
  );

  wrapSimple(request);
};

export const setSnippetProp = (data) => {
  const {
    snippetAtPage, id, language,
  } = data;

  const requestData = {
    ...data,
    ui: getCurrentUi(),
  };

  const request = () => requestWrapper(
    SNIPPETS_AT_PAGE.SET_PROPS,
    `${API_SNIPPETS_AT_PAGE_URL}${snippetAtPage}/values/${language}/${id}/?key=${baseApi.AddToken}`,
    'post',
    false,
    requestData,
    true,
  );

  wrapSimple(request);
};
