import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { confirmAlert } from 'react-confirm-alert';
import { listSelector, pageSelector, highlightSelector } from '../services/selectors/snippet-at-page';
import {
  getList, createSnippet, editSnippet, removeSnippet, selectSnippet,
} from '../services/actions/snippet-at-page';
import createLoadingSelector from '../services/selectors/loading';
import { SNIPPETS_AT_PAGE } from '../constants/type';
import { promptOptions } from '../utils/promptOptions';
import { translate } from '../utils/translate';
import { find } from 'lodash';

const selector = createSelector(
  listSelector,
  createLoadingSelector(SNIPPETS_AT_PAGE.GET_LIST.base),
  pageSelector,
  highlightSelector,
  (snippetsAtPage, snippetsAtPageLoader, snippetsAtPagePage, snippetsAtPageHighlight) => ({
    snippetsAtPage,
    snippetsAtPageLoader,
    snippetsAtPagePage,
    snippetsAtPageHighlight,
  }),
);

export const useSnippetsAtPage = (pageId) => {
  const data = useSelector(selector);

  const onCreateSnippet = createSnippet;
  const onEditSnippet = editSnippet;
  const onSelectSnippet = selectSnippet;
  const onRemoveSnippet = (id) => {
    const _id = data && data.snippetsAtPage ? find(data.snippetsAtPage, {'id': id}) : undefined;
    const snp = _id && _id.order ? `snippet #${_id.order}` : 'this snippet';
    confirmAlert(promptOptions({
      title: translate('Delete snippet from page'),
      message: translate(`Are you sure to remove ${snp} from page?`),
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => removeSnippet(id),
        },
      ],
    }));
  };

  useEffect(() => {
    const { loading, loaded, error } = data.snippetsAtPageLoader;

    if (!loading
        && (!loaded || (pageId !== data.snippetsAtPagePage && data.snippetsAtPagePage !== 0))
        && !error) getList(pageId);
  }, [data.snippetsAtPageLoader, pageId, data.snippetsAtPagePage]);

  return {
    ...data,
    onCreateSnippet,
    onEditSnippet,
    onSelectSnippet,
    onRemoveSnippet,
  };
};
