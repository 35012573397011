import { createSelector } from 'reselect';

export const snippetsAtPageSelector = (state) => state.snippetsAtPage;

export const listSelector = createSelector(
  snippetsAtPageSelector,
  (snippetsAtPage) => snippetsAtPage.list,
);

export const highlightSelector = createSelector(
  snippetsAtPageSelector,
  (snippetsAtPage) => snippetsAtPage.highlight,
);

export const pageSelector = createSelector(
  snippetsAtPageSelector,
  (snippetsAtPage) => snippetsAtPage.page,
);

export const snippetAtPageIdSelector = (state, snippetAtPageId) => snippetAtPageId;

export const snippetAtPageSelector = createSelector(
  listSelector,
  snippetAtPageIdSelector,
  (snippetsAtPage, snippetAtPageId) => snippetsAtPage
    // eslint-disable-next-line eqeqeq
    .find((snippetAtPage) => snippetAtPage.id == snippetAtPageId),
);

export const propsSelector = createSelector(
  snippetsAtPageSelector,
  (snippetsAtPage) => snippetsAtPage.props,
);
