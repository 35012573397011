import axios from 'axios';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { translate } from './translate';

// Set up Base Axios instance
axios.defaults.headers.post['Content-Type'] = 'application/json';

const baseAxiosInstance = axios.create();

baseAxiosInstance.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_URL}`;
export const baseWsUrl = process.env.REACT_APP_BASE_URL.replace('http://', 'ws://').replace('https://', 'wss://');

baseAxiosInstance.defaults.headers.common.Authorization = localStorage.getItem('auth')
  || sessionStorage.getItem('auth')
  || '';

baseAxiosInstance.AddToken = localStorage.getItem('additional')
  || sessionStorage.getItem('additional')
  || '';

baseAxiosInstance.interceptors.response.use((response) => response.data, (e) => {
  const response = get(e, 'response', {});
  let responseError = get(e, 'response.data', {});

  if (typeof responseError === 'string' && response.status === 500) {
    responseError = {};
  }

  if ((!response.status || response.status === 500)) {
    if (axios.isCancel(e)) {
      toast.error(translate(`Request canceled: ${e.message}`));
    } else {
      toast.error(translate('Don\'t worry. Something went wrong. Please try again later.'));
    }
  }

  const error = {
    status: response.status || 500,
    message: responseError.Message || 'Something went wrong',
    data: responseError,
  };

  return Promise.reject(error);
});

export const setAuthHeader = (token, additional, remember, callBack) => {
  if (remember) {
    localStorage.setItem('auth', token);
    localStorage.setItem('additional', additional);
  } else {
    sessionStorage.setItem('auth', token);
    sessionStorage.setItem('additional', additional);
  }

  baseAxiosInstance.defaults.headers.common.Authorization = token;
  baseAxiosInstance.AddToken = additional;

  callBack();
};

export const removeAuthHeader = (callBack) => {
  localStorage.removeItem('auth');
  localStorage.removeItem('additional');

  sessionStorage.removeItem('auth');
  sessionStorage.removeItem('additional');

  baseAxiosInstance.defaults.headers.common.Authorization = '';
  baseAxiosInstance.AddToken = '';

  callBack();
};

export const baseApi = baseAxiosInstance;
