import { createRequestTypes } from 'utils/actionHelpers';

export const WEBSITES = createRequestTypes('WEBSITES', [
  /* WEBSITES */
  'GET_LIST',
  'CREATE',
  'EDIT',
  'REMOVE',

  'GET_CURRENT_WEBSITE',
  'CLEAR_CURRENT_WEBSITE',
  'GET_LANGUAGES',
  'GET_DOMAIN',

  'GET_FILES',
  'CREATE_FILE',
  'EDIT_FILE',
  'REMOVE_FILE',

  'GET_CATEGORIES',
  'CREATE_CATEGORY',
  'EDIT_CATEGORY',
  'REMOVE_CATEGORY',
]);

export const PAGES = createRequestTypes('PAGES', [
  /* PAGES */
  'GET_LIST',
  'CREATE',
  'EDIT',
  'REMOVE',
  'GET_CURRENT_PAGE',
  'GET_CHILDREN',
  'GET_LIST_SEARCH',
  'CLEAR_LIST_SEARCH',

  'ACTION',
]);

export const SNIPPETS = createRequestTypes('SNIPPETS', [
  /* SNIPPETS */
  'GET_LIST',
  'GET_FULL',
  'CREATE',
  'EDIT',
  'REMOVE',

  'GET_CATEGORIES',
  'CREATE_CATEGORY',
  'EDIT_CATEGORY',
  'REMOVE_CATEGORY',

  'SET_HELPER_VALUES',
  'SET_HELPER_ACTIONS',
  'SET_HELPER_DISABLE',

  'GET_KEY',
]);

export const SNIPPETS_AT_PAGE = createRequestTypes('SNIPPETS_AT_PAGE', [
  /* SNIPPETS AT PAGE */
  'GET_LIST',
  'CHANGE_ORDER',
  'SET_SNIPPET',
  'REMOVE_SNIPPET',
  'SELECT_SNIPPET',
  'SET_HIGHTLIGHT',

  'SET_PROPS',
  'GET_PROPS',
]);

export const MEDIA = createRequestTypes('MEDIA', [
  /* MEDIA */
  'GET_ALL',
  'GET_ALL_BY_PATH',
  'CREATE',
  'CANCEL',
  'EDIT',
  'REMOVE',

  'GET_CURRENT_CATEGORY',

  'CREATE_CATEGORY',
  'EDIT_CATEGORY',
  'REMOVE_CATEGORY',
]);

export const AUTH = createRequestTypes('AUTH', [
  /* AUTH */
  'SIGN_IN',
  'SIGN_OUT',
]);

export const UI = createRequestTypes('UI', [
  /* UI */
  'SELECTED_PAGE',
  'SELECT_LEFT_MENU',
  'CHANGE_LANGUAGE',
]);

export const TEMPLATES = createRequestTypes('TEMPLATES', [
  /* TEMPLATES */
  'GET_LIST',
  'CREATE',
  'EDIT',
  'REMOVE',
  'APPLY',
]);

export const REDIRECTS = createRequestTypes('REDIRECTS', [
  /* REDIRECTS */
  'GET_LIST',
  'CREATE',
  'EDIT',
  'REMOVE',
]);
